<template>
    <div>
        <el-carousel height="600px" interval="5000">
            <el-carousel-item>
                <img src="http://home.hzhjny.com/var/file/banner1.png" alt class="bannerimg" />
            </el-carousel-item>
            <el-carousel-item>
                <img src="http://home.hzhjny.com/var/file/banner2.png" alt class="bannerimg" />
            </el-carousel-item>
        </el-carousel>
        <div class="content">
            <div class="title">
                公司介绍
            </div>
            <div class="engtxt">
                <span> COMPANY PROFILE</span>
                <span class="welctext">欢迎加入我我们</span>
                <span class="titline"></span>
            </div>
            <div class="introduce">
                专业从事电力监测、电力服务、新能源开发及智能化AI系统设计开发。
            </div>


            <el-row>
                <el-col :span="12">
                    <div class="linemain">
                        <span class="titleline"></span>
                        企业优势
                    </div>

                    <div class="linetext">
                        本公司各类设备在浙江地区已实现大规模的应用，设备的性能也得到了广大用的好评。本公司针对电力行业的各类检测服务也得到客户了响应速度快、服务质量好的肯定。
                    </div>

                    <div class="linemain">
                        <span class="titleline"></span>
                        产品优势
                    </div>

                    <div class="linetext">
                        公司产品涉及线路动态监测、视频监测以及服务检测等。公司成员具备多年相关行业工作经验，能够从实际出发，将产品的功能和服务的内容满足于电力行业的需求
                    </div>

                    <div class="linemain">
                        <span class="titleline"></span>
                        企业文化
                    </div>

                    <div class="linetext">
                        <div class="circlemain">
                            <span class="circleleft"></span> 价值观
                        </div>
                        <div style="font-size: 16px;">
                            和合共生 继往开来
                        </div>
                        <div class="circlemain">
                            <span class="circleleft"></span> 使命
                        </div>
                        <div style="font-size: 16px;">
                            引领智慧电力 共创智能电网
                        </div>
                        <div class="circlemain">
                            <span class="circleleft"></span> 价值观
                        </div>
                        <div style="font-size: 16px;">
                            做一流电力综合服务企业 推动电力行业智能化未来
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div style="padding:20px">
                        <img src="http://home.hzhjny.com/var/file/banner4.jpg" alt="" style="width:100%;">
                    </div>
                </el-col>
            </el-row>


            <div class="title" style="margin-top: 20px;">
                经营范围
            </div>
            <div class="engtxt">
                <span> Business Scope</span>
                <span class="more">查看更多</span>
                <span class="titline"></span>
            </div>

            <div class="mt20">
                <el-row>
                    <el-col :span="12">
                        <div style="padding:20px">
                            <img src="http://home.hzhjny.com/var/file/banner3.jpg" alt="" style="width:100%;">
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="linemain">
                            经营范围
                        </div>

                        <div class="linetext">
                            电力设施承装、承修、承试(依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准)。一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；机械电气设备制造；输配电及控制设备制造；电子元器件与机电组件设备制造；电气设备销售；智能输配电及控制设备销售；电子元器件与机电组件设备销售；计算机软硬件及辅助设备零售；机械设备租赁；电气设备修理(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)。
                        </div>


                    </el-col>

                </el-row>
            </div>



            <div class="title" style="margin-top: 20px;">
                产品中心
            </div>
            <div class="engtxt mt10">
                <span> PRODUCTS</span>
                <span class="titline"></span>
            </div>


            <div class="mt20">
                <el-row>
                    <el-col :span="8" style="padding: 10px;">

                        <div class="bordermain" @click="lookmore1">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product11.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    导线精灵
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    导线精灵是一款集图像视频、导线测温、导线弧垂、覆冰等一体的在线监测装置。直接安装在输电线路导线上，内置AI处理器 ，可
                                    通过覆冰图像信息算出覆冰厚度，装置通过4G传输方式向中心站发送监测图像、视频、传感器和状态信息等数据。当相关信息超过设置的阈值后，后台将发送相关预警信息。
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" style="padding: 10px;">

                        <div class="bordermain" @click="lookmore2">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product10.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    微拍监测装置-枪机式
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    枪机式微拍监测装置是一款聚焦于解决输电线路杆塔双侧通道监控、塔基防外破及塔上设备互联为核心，集智能四摄、AI前置及超低功耗功能于一体的图像在线监控装置，支持定时自动抓拍和手动抓拍，支持20s短视频拍摄和传输。并具备AI识别功能，例如绝缘子
                                    、间隔棒 、防振锤等金具均可识别，也可对环境进行识别如塔吊, 挖掘机, 泵车等大型机械以及烟雾, 火点等安全隐患。
                                </div>
                            </div>
                        </div>

                    </el-col>
                    <el-col :span="8" style="padding: 10px;">
                        <div class="bordermain" @click="lookmore3">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product12.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    微拍监测装置-云台式
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    云台式微拍视频装置是集摄像、存储、传输、太阳能取电为一体的工业化、智能化、一体化设计的输电线路图像视频监测装置。其主要为了针对输电线廊区域 、
                                    塔基区域等周边环境实现全面的掌握，可利用云台特性根据需要进行预置位定时抓拍监控并实时调取视频数据，第一时间掌握现场情况。
                                </div>
                            </div>
                        </div>

                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="8" style="padding: 10px;">
                        <div class="bordermain" @click="lookmore4">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product13.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    三跨在线视频装置
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    输电线路分布区域广、传输距离长、容易受环境气候影响以及人们生活、生产活动的干扰。 对于“三跨"地区，由于涉及的范围广，对其安全防护更为重要。
                                    我们针对输电线路对关键点实时监控的需求，严格依据国家电网企业制定的相关标准，推出了输电线路三跨在线视频装置，为电力企业的安全生产保驾护航
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" style="padding: 10px;">

                        <div class="bordermain" @click="lookmore5">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product14.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    微气象监测装置
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    微气象在线监测系统是针对输电线路、变电站等在特殊地点的气象环境监测而设计的多要素微气象监测系统。可监测环境温度、湿度、风速、风向、气压、光辐射、
                                    降雨量等气象参数，并将采集到的各种气象参数及其变化状况，通过GPRS无线网络实时的传送到系统主机中。
                                </div>
                            </div>
                        </div>

                    </el-col>
                    <el-col :span="8" style="padding: 10px;">
                        <div class="bordermain" @click="lookmore6">
                            <div>
                                <img src="http://home.hzhjny.com/var/file/product25.png" alt="" class="proimg">
                            </div>
                            <div class="productcont">
                                <div class="producttitle">
                                    杆塔倾斜在线监测装置
                                </div>
                                <hr class="producthr">
                                <div class="productcontent">
                                    杆塔倾斜在线监测装置是一套基于高精度定位与多传感器融合的输电线路杆塔倾斜在线监测终端，能全天候监测杆塔倾斜情况、晃动幅值与晃动轨迹数据，并能通过4G上报数据。
                                    使维护人员能够在后端查看现场数据，及时发现隐患、排除隐患，提高输电线路工作的可靠性。
                                </div>
                            </div>
                        </div>

                    </el-col>
                </el-row>
            </div>


            <div class="title" style="margin-top: 20px;">
                新闻咨询
            </div>
            <div class="engtxt mt10">
                <span> News Center</span>
                <span class="titline"></span>
            </div>
            <div class="mt20">
                <el-row>
                    <el-col :span="12">
                        <div style="padding:10px" class="box" @click="new1">
                            <img src="http://home.hzhjny.com/var/file/newsimg.jpg" alt=""
                                style="width:100%;height: 200px;">
                            <div>
                                <span class="datetext">2023-11-09</span>
                                <div class="newstitle">
                                    迎峰度冬电力供应有保障
                                </div>
                                <div class="newsline">

                                </div>
                                <div class="newscont">
                                    随着北方地区陆续供暖，全社会用电量不断攀升，冬季电力保障工作受到广泛关注。国家能源局相关负责人介绍，预计迎峰度冬期间全国最高负荷较去年同期增加1.4亿千瓦，出现较大幅度增长。从目前掌握数据来看，迎峰度冬电力保供总体有保障，局部面临一定压力。
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="padding:10px" class="box" @click="new2">
                            <img src="http://home.hzhjny.com/var/file/newsimg1.jpg" alt=""
                                style="width:100%;height: 200px;">
                            <div>
                                <span class="datetext">2023-11-01</span>
                                <div class="newstitle">
                                    中国电力：创新引领未来能源
                                </div>
                                <div class="newsline">

                                </div>
                                <div class="newscont">
                                    10月29日，中国电力面向全球成功发布6项科技创新成果，引领绿能新兴产业发展新潮流，进一步展现了其“打造科技创新引领型的世界一流绿色低碳能源供应商”的最新进展。
                                </div>
                            </div>
                        </div>
                    </el-col>

                </el-row>
            </div>


            <div class="title" style="margin-top: 20px;">
                专利荣誉
            </div>
            <div class="engtxt mt10">
                <span> Honor Center</span>
                <span class="titline"></span>
            </div>
            <div style="margin-top: 20px;">
                <!-- <el-carousel :interval="4000" height="200px">
                    <el-carousel-item v-for="item in photolist" :key="item">
                        <img :src="item" alt=""  style="width:100%">
                    </el-carousel-item>
                </el-carousel> -->
                <marquee :interval="100" :step="4" :endWidth="2000" ref="test" behavior="alternate"
                    onmouseover="this.stop();" onmouseout="this.start();">
                    <div style="display: flex">
                        <a href="http://home.hzhjny.com/var/file/photo1.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo1.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo2.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo2.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo3.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo3.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo4.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo4.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo5.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo5.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo6.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo6.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo7.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo7.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo8.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo8.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo9.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo9.png" alt="" srcset="" style="width: 200px;"
                                class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo10.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo10.png" alt="" srcset=""
                                style="width: 200px;" class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo11.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo11.png" alt="" srcset=""
                                style="width: 200px;" class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo12.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo12.png" alt="" srcset=""
                                style="width: 200px;" class="imgtype" />
                        </a>
                        <a href="http://home.hzhjny.com/var/file/photo13.png" target="_blank">
                            <img src="http://home.hzhjny.com/var/file/photo13.png" alt="" srcset=""
                                style="width: 200px;" class="imgtype" />
                        </a>
                    </div>
                </marquee>
            </div>


        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                // photolist: [
                //     'http://home.hzhjny.com/var/file/photo1.png',
                //     'http://home.hzhjny.com/var/file/photo2.png',
                //     'http://home.hzhjny.com/var/file/photo3.png',
                //     'http://home.hzhjny.com/var/file/photo4.png',
                //     'http://home.hzhjny.com/var/file/photo5.png',
                //     'http://home.hzhjny.com/var/file/photo6.png',
                //     'http://home.hzhjny.com/var/file/photo7.png',
                //     'http://home.hzhjny.com/var/file/photo8.png',
                //     'http://home.hzhjny.com/var/file/photo9.png',
                //     'http://home.hzhjny.com/var/file/photo10.png',
                //     'http://home.hzhjny.com/var/file/photo11.png',
                //     'http://home.hzhjny.com/var/file/photo12.png',
                //     'http://home.hzhjny.com/var/file/photo13.png',
                // ]
            }
        },
        methods: {
            lookmore1() {
                localStorage.setItem('productnum', '/productdetail/sdproduct1')
                window.location.href = '/productdetail/sdproduct1'
            },
            lookmore2() {
                localStorage.setItem('productnum', '/productdetail/sdproduct2')
                window.location.href = '/productdetail/sdproduct2'
            },
            lookmore3() {
                localStorage.setItem('productnum', '/productdetail/sdproduct3')
                window.location.href = '/productdetail/sdproduct3'
            },
            lookmore4() {
                localStorage.setItem('productnum', '/productdetail/sdproduct4')
                window.location.href = '/productdetail/sdproduct4'
            },
            lookmore5() {
                localStorage.setItem('productnum', '/productdetail/sdproduct5')
                window.location.href = '/productdetail/sdproduct5'
            },
            lookmore6() {
                localStorage.setItem('productnum', '/productdetail/sdproduct6')
                window.location.href = '/productdetail/sdproduct6'
            },

            new1() {
                window.location.href = '/newsdetail1'
            },

            new2() {
                window.location.href = '/newsdetail2'
            },

            startScroll() {
                console.log(111)
                let marqueeRef = this.$refs.test;
                marqueeRef.run();
            },
            stopScroll() {
                console.log(222)
                let marqueeRef = this.$refs.test;
                console.log(marqueeRef)
                clearInterval(marqueeRef.mysetInterval);
            },
        }
    }
</script>
<style scoped>
    .content {
        width: 70%;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .title {
        font-size: 30px;
    }

    .bannerimg {
        width: 100%;
        height: 600px;
    }

    .engtxt {
        font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .welctext {
        color: #3e5c38;
        font-weight: bold;
    }

    .more {
        background: #0960f1;
        width: 100px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
    }

    .titline {
        background: #0960f1;
        width: 100px;
        height: 3px;
        position: absolute;
        bottom: -1px;
        left: 0;
    }

    .introduce {
        font-size: 14px;
        margin-top: 20px;
    }


    .linemain {
        font-size: 22px;
        display: flex;
        align-items: center;
        margin-top: 30px;
    }

    .titleline {
        background: #0960f1;
        width: 6px;
        height: 30px;
        display: inline-block;
        margin-right: 20px;
        border-radius: 5px;
    }

    .linetext {
        font-size: 14px;
        line-height: 30px;
        margin-top: 20px;
    }

    .bordermain {
        width: 100%;
        height: 512px;
        border: 1px solid #e9e9e9;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        background: white;
    }

    .bordermain:hover {
        box-shadow: 0px 0px 1rem #ccc;
        transition: 0.5s all;

    }

    .proimg {
        width: 100%;
        max-height: 400px;
        transition: 0.5s all;
        overflow: hidden;
    }

    .bordermain:hover .proimg {
        width: 110%;
        max-height: 450px;
        height: auto;
    }

    .productcont {
        padding: 20px;
        position: absolute;
        width: 100%;
        bottom: 0;
        box-sizing: border-box;
    }

    .producttitle {
        font-size: 22px;
        font-weight: bold;
    }

    .productcontent {
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .producthr {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .box {
        transition: all .5s ease;
        cursor: pointer;
    }

    .box:hover {
        transform: translateY(-15px);
    }

    .datetext {
        font-size: 14px;
        color: #999;
        margin-top: 20px;
        display: block;
    }

    .newstitle {
        font-size: 18px;
        color: #333;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .newscont {
        font-size: 14px;
        color: #999;
        line-height: 30px;
        height: 60px;
        width: 100%;
        overflow: hidden;
        white-space: normal;
        word-break: break-word;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    .newsline {
        width: 50px;
        height: 1px;
        background: #333;
        margin-top: 10px;
        margin-bottom: 10px;
        transition: all .5s ease;
    }

    .box:hover .newsline {
        width: 100%;
    }

    .circleleft {
        display: inline-block;
        border-radius: 50%;
        background: #1264f1;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    .circlemain {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
    }

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .imgtype {
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle
    }
</style>